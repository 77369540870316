<template>
  <v-container fluid>
    <modal-base />

    <v-row md="12" sm="12" cols="12">
      <v-col md="1" sm="12" cols="12" />

      <v-col md="10" sm="12" cols="12">
        <PlanoCampos />

        <v-col>
          <v-row>
            <v-col md="3" sm="12" cols="12">
              <v-text-field
                v-model="$store.state.plano.tickets_usados"
                outline
                prepend-icon="mdi-ticket  "
                hide-details="auto"
                class="custom-label-color"
                label="Ticket Usados"
                :readonly="true"
              />
            </v-col>
            <v-col md="3" sm="12" cols="12">
              <v-text-field
                v-model="$store.state.plano.planoAtual.tickets"
                outline
                prepend-icon="mdi-ticket-confirmation  "
                hide-details="auto"
                class="custom-label-color"
                label="Total de Tickets"
                :readonly="true"
              />
            </v-col>
            <v-col md="3" sm="12" cols="12" class="d-none">
              <v-text-field
                outline
                prepend-icon="mdi-tag-multiple"
                hide-details="auto"
                class="custom-label-color"
                label="Total de Notas"
                :readonly="true"
              />
            </v-col>
            <v-col md="3" sm="12" cols="12" class="d-none">
              <v-text-field
                outline
                prepend-icon="mdi-tag"
                hide-details="auto"
                class="custom-label-color"
                label="Notas Disponíveis"
                :readonly="true"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-col>

      <v-col md="1" sm="12" cols="12" />
    </v-row>
  </v-container>
</template>

<script>
export default {
  components: {
    PlanoCampos: () => import("./PlanoCampos"),
  },
};
</script>
<style lang="scss" >
.custom-label-color .v-label {
  font-size: 20px !important ;
}
</style>

